import { css, createGlobalStyle } from 'styled-components';

const Reset = css`
/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-weight: 400;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
    display: none;
}
body {
  line-height: 1;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
`

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Rubik');
  @import url('https://fonts.googleapis.com/css?family=Slabo+13px');

  ${Reset}

  h1, h2, h3 {
    font-family: 'Slabo 13px', serif;
    font-weight: 400;
  }

  h4, h5, h6 {
    font-weight: 200;
  }

  ul {
    margin: 18px;
  }

  p {
    margin: 0 0 24px 0;
  }

  body {
    font-family: 'Rubik', 'Helvetica Neue', arial, sans-serif;
    font-size: 14px;
		line-height: 1.5rem;
    font-weight: 400;

    @media (min-width: 768px) {
      font-size: 17px;
		  line-height: 1.75rem;
    }
  }
`

export {
  GlobalStyle
}